// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/ShortcutKey.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/ShortcutKey.tsx");
  import.meta.hot.lastModified = "1713806390102.8496";
}
// REMIX HMR END

import { Fragment } from "react";
import { cn } from "~/utils/cn";
import { useOperatingSystem } from "./OperatingSystemProvider";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
export const variants = {
  small: "text-[0.6rem] font-medium min-w-[17px] rounded-[2px] px-1 ml-1 -mr-0.5 grid place-content-center border border-dimmed/40 text-text-dimmed group-hover:text-text-bright/80 group-hover:border-dimmed/60 transition uppercase",
  medium: "text-[0.75rem] font-medium min-w-[17px] rounded-[2px] px-1 ml-1 -mr-0.5 grid place-content-center border border-dimmed/40 text-text-dimmed group-hover:text-text-bright/80 group-hover:border-dimmed/60 transition uppercase"
};
export function ShortcutKey({
  shortcut,
  variant,
  className
}) {
  _s();
  const {
    platform
  } = useOperatingSystem();
  const isMac = platform === "mac";
  let relevantShortcut = "mac" in shortcut ? isMac ? shortcut.mac : shortcut.windows : shortcut;
  const modifiers = relevantShortcut.modifiers ?? [];
  const character = keyString(relevantShortcut.key, isMac, variant);
  return <span className={cn(variants[variant], className)}>
      {modifiers.map(k => <Fragment key={k}>{modifierString(k, isMac)}</Fragment>)}
      {character}
    </span>;
}
_s(ShortcutKey, "BLQEs2iULPGbBKHlYR5bS3A8br8=", false, function () {
  return [useOperatingSystem];
});
_c = ShortcutKey;
function keyString(key, isMac, size) {
  key = key.toLowerCase();
  const className = size === "small" ? "w-2.5 h-4" : "w-3 h-5";
  switch (key) {
    case "enter":
      return isMac ? "↵" : key;
    case "arrowdown":
      return <ChevronDownIcon className={className} />;
    case "arrowup":
      return <ChevronUpIcon className={className} />;
    case "arrowleft":
      return <ChevronLeftIcon className={className} />;
    case "arrowright":
      return <ChevronRightIcon className={className} />;
    default:
      return key;
  }
}
function modifierString(modifier, isMac) {
  switch (modifier) {
    case "alt":
      return isMac ? "⌥" : "Alt+";
    case "ctrl":
      return isMac ? "⌃" : "Ctrl+";
    case "meta":
      return isMac ? "⌘" : "⊞+";
    case "shift":
      return isMac ? "⇧" : "Shift+";
    case "mod":
      return isMac ? "⌘" : "Ctrl+";
  }
}
var _c;
$RefreshReg$(_c, "ShortcutKey");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;